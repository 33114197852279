import { registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localePlExtra from '@angular/common/locales/extra/pl';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { SpeedTestModule } from 'ng-speed-test';

import { ApiErrorInterceptor } from './api-errors/interceptors/api-error/api-error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ToastsModule } from './toasts/toast.module';
import { AppComponent } from './app.component';
import { AuthAccessTokenHeaderInterceptor } from './auth/interceptors/auth-access-token-header/auth-access-token-header.interceptor';
import { AuthTokenService } from './auth/services/auth-token/auth-token.service';
import { AuthUserService } from './auth/services/auth-user/auth-user.service';
import { CurrentConnectionService } from './connector/services/current-connection/current-connection.service';
import { FormControlDatepickerDateFormatterService } from './forms/services/form-control-datepicker-date-formatter/form-control-datepicker-date-formatter.service';
import { CurrentLanguageService } from './i18n/services/current-language/current-language.service';
import { CurrentSettingsService } from './settings/services/current-settings/current-settings.service';
import { SatisfactionFeedbackScriptService } from './shared/services/satisfaction-feedback-script/satisfaction-feedback-script.service';
import { ContentLanguageHeaderInterceptor } from './i18n/interceptors/content-language-header/content-language-header.interceptor';
import { CurrentUserPermissionsService } from './user-roles/services/current-user-permissions/current-user-permissions.service';
import { AppInitializerFactory } from './core/factories/app-initializer/app-initializer.factory';
import { TranslateLoaderFactory } from './i18n/factories/translate/translate.factory';
import { LottiePlayerFactory } from './shared/factories/lottie-player/lottie-player.factory';
import { RecipeDetailsModule } from './recipe-details/recipe-details.module';

registerLocaleData(localePl, 'PL', localePlExtra);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SharedModule,
    ToastsModule,
    SpeedTestModule,
    LottieComponent,
    RecipeDetailsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [CurrentLanguageService, CurrentSettingsService, AuthUserService, CurrentConnectionService, CurrentUserPermissionsService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthAccessTokenHeaderInterceptor,
      multi: true,
      deps: [AuthTokenService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
      deps: [Injector, Router, AuthUserService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentLanguageHeaderInterceptor,
      multi: true,
      deps: [CurrentLanguageService],
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: NgbDateParserFormatter, useClass: FormControlDatepickerDateFormatterService },
    SatisfactionFeedbackScriptService,
    provideLottieOptions({
      player: LottiePlayerFactory,
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
